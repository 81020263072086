export class OrderItem
{
    constructor(
        public _id: String,
        public OrderNo: Number,
        public Name: String,
        public Qty: Number,
        public Perference: String,
        public Attribute1: String,
        public Attribute2: String,
        public Attribute3: String
    ){

    }
}