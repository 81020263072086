import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Product, UpdateOrderAction } from '../../../services/models/models';
import { OrderService } from '../../../services/order-service/order-service';
import { GlobalConstants } from '../../../common/global-constants';

@Component({
  selector: 'app-menu-category-item',
  templateUrl: './menu-category-item.component.html',
})
export class MenuCategoryItemComponent {
  @Input()
  product: Product = undefined;

  @Output()
  click = new EventEmitter<UpdateOrderAction>();

  amount: number = 0;
  orderId:string;
  exists : number ;

  constructor(private orderService: OrderService) {
    this.orderService.clearOrder$.subscribe(()=>{
      this.amount = 0;
      sessionStorage.removeItem(`${this.product.Name},${this.product.Price},${this.product.Type}`)
    });
  }
  
  ngOnInit() {}

  private emitAction(action: "add" | "remove") {
    // Save this in session storage
    // if(this.amount = 0)
    // {
    //   console.log(sessionStorage.getItem(`${this.product.Name},${this.product.Price},${this.product.Type}`))
    // }
         sessionStorage.setItem(`${this.product.Name},${this.product.Price},${this.product.Type}`, this.amount.toString())
    
    //console.log(sessionStorage.getItem(`${this.product.Name},${this.product.Price}`))

    this.click.emit({
      action,
      name: this.product.Name,
      price: this.product.Price || 0
    });
  }

  increaseAmount() {
    event.stopPropagation();
    this.amount = this.amount + 1;
    this.emitAction("add");
  }

  decreaseAmount() {
    event.stopPropagation();
    this.amount = Math.max(this.amount - 1, 0);
    this.emitAction("remove");
  }
}