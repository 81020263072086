import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "../../services/authentication-service";
import { CommonModule } from '@angular/common';
import { GlobalConstants } from '../../../app/common/global-constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from 'src/app/services/models/user';
import { UserService } from '../../services/user-service/user-service';
import { BarcodeFormat } from '@zxing/library';
import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TableService } from '../../services/table-service/table-service';
import { Table } from 'src/app/services/models/table';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  username: string;
  mobilenumber: string;
  password:string;
  errorMessage: string;
  isadmin = false;
  user: User;
  subscribParam = "";
  response : User;
  scanner = false;
  normal = true;
  reserved = false;
  table:Table;
  @ViewChild('nameit') 
  elementRef: ElementRef;

  qrResultString: string;
  openScanner: boolean = false;


  // clearResult(): void {
  //   this.qrResultString = null;
  // }

  // toggleScanner() {
  //   this.openScanner = !this.openScanner;
  // }

  // onCodeResult(resultString: string) {
  //   this.qrResultString = resultString;
  //   window.open(this.qrResultString, '_self');
  // }
  
  constructor(private router: Router, 
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private userService: UserService,
    private toastr:ToastrService,
    private tableService: TableService) { 
    }

  ngOnInit() {

    this.route.queryParams.subscribe(param =>{
      this.subscribParam = param['tbno'];
      sessionStorage.setItem('tableno',this.subscribParam);
      //localStorage.setItem('isadmin', 'false');
      //alert("tableno:" + this.subscribParam);
    });

    if(this.subscribParam == "admin"){
      this.isadmin = true;
      //localStorage.setItem('isadmin', this.isadmin.toString());
    }
    if(this.subscribParam == undefined)
    {
      this.toastr.warning("Please ReScan");
      this.scanner = true;
      this.normal = false;
    }
    else 
    {
      this.tableService.getTableByNum(sessionStorage.getItem('tableno')).subscribe(res=>{
        if(res[0].active == "Reserved")
        {
          //this.toastr.warning("This Table is Reserved. Please Contact Admin");
          this.normal=false;
          this.reserved = true;
        }
      });
    }
  }


  onPasswordKeyDown(evt) {
    if (evt.key === "Enter") {
      this.login();
    }
  }
  login() {
    
      this.user = new User(this.username, this.mobilenumber, this.password, this.isadmin)
      this.userService.getUserbyUserName(this.user).subscribe(res=> {
        this.response = res[0];
        if(this.response!= null)
        {
          if(this.response.isadmin && this.response.password == this.password && this.response.mobilenumber == this.mobilenumber)
          {
            this.router.navigate(['/tables']);
          }

          else if(this.response.isadmin == false && this.response.mobilenumber == this.mobilenumber) {
            sessionStorage.setItem('username',this.username);
            sessionStorage.setItem('mobilenumber',this.mobilenumber); 
            this.tableService.getTableByNum(sessionStorage.getItem('tableno')).subscribe(res=>
              {
                  this.table = res[0];
                  this.table.currentUser = sessionStorage.getItem('username') +"/"+ sessionStorage.getItem('mobilenumber');
                  this.table.active = 'Yes';
                  if(res[0]!=null)
                  {
                          this.tableService.updateTable(this.table).subscribe(res=>{
                                  console.log('table updated !!');
                          });
                  }
              });
            this.router.navigate(['/home']);
            }
            else {
              this.errorMessage = "Invalid Credentials!";
            }
        }

        if(this.response == null)
        {
          //create user and navigate to page
          if(this.isadmin)
          {
            this.errorMessage = "Invalid Credentials!";
          }
          else {
            this.userService.createUser(this.user).subscribe(res=>{
              if(res!= null)
              {
                sessionStorage.setItem('username',this.username);
                sessionStorage.setItem('mobilenumber',this.mobilenumber);
                this.tableService.getTableByNum(sessionStorage.getItem('tableno')).subscribe(res=>
                  {
                      this.table = res[0];
                      this.table.currentUser = sessionStorage.getItem('username') +"/"+ sessionStorage.getItem('mobilenumber');
                      this.table.active = 'Yes';
                      if(res[0]!=null)
                      {
                              this.tableService.updateTable(this.table).subscribe(res=>{
                                      console.log('table updated !!');
                              });
                      }
                  });
                this.router.navigate(['/home']);
              }
            });
          }
        }
      });

    }

  error()
  {
    this.errorMessage = "Please Enter Name and Number";
  }
  register() 
  {
    this.router.navigate(['/auth/register']);
  }
}
