import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http'; 
import { HttpModule } from '@angular/http';
import { User } from '../../services/models/user';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
//import { environment } from '../../../../environments/environment';

@Injectable()
export class UserService {

  constructor(private http: HttpClient) { }

  // addUser(userModel: User): Observable<any> {
  //   return this.http.post(environment.apis.addUser, userModel);
  // }

  // getallUsers(): Observable<any> {
  //   return this.http.get(environment.apis.getallUsers);
  // }

  getUserbyUserName(user :User): Observable<any>
  {
    return this.http.post(environment.apis.getUserbyUserName,user);
  }

  createUser(user: User) : Observable<any> {
    return this.http.post(environment.apis.createuser, user);
  }
}
