import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';

import { NgbModule, NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { AuthenticationService } from './services/authentication-service';
import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';
import { MenuCategoryItemComponent } from './pages/menu/menu-category-item/menu-category-item.component';
import { MenuCategoryComponent } from './pages/menu/menu-category/menu-category.component';
import { MenuComponent } from './pages/menu/menu.component';
import { OrderStatusComponent } from './pages/order-status/order-status.component';
import { HomeComponent } from './pages/home/home.component';
import { ToastrModule } from 'ngx-toastr';
import { FilterPipe } from '../app/common/filter.pipe';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { AutofocusDirective } from '../app/utils/directives/autofocus.directive';
import { ReactiveFormsModule, FormControl} from '@angular/forms';
import { UserService } from './services/user-service/user-service';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { TableService } from './services/table-service/table-service';
@NgModule({
  imports: [
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    ComponentsModule,
    NgbModule,
    RouterModule,
    AppRoutingModule,
    CommonModule,
    BrowserModule,
    ToastrModule.forRoot(),
    MatDialogModule,
    MatInputModule, 
    MatButtonModule, 
    MatCardModule, 
    MatFormFieldModule,
    NgbRatingModule,
    ZXingScannerModule                                                                                                          
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    HomeComponent,
    OrderStatusComponent,
    MenuComponent,
    MenuCategoryComponent,
    MenuCategoryItemComponent,
    FilterPipe,
    AutofocusDirective
  ],
  providers: [AuthenticationService, UserService, TableService],
  bootstrap: [AppComponent]
})
export class AppModule { }
