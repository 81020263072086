import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ProductCategory, UpdateOrderAction } from '../../../services/models/models';

@Component({
  selector: 'app-menu-category',
  templateUrl: './menu-category.component.html',
  styleUrls: ['./menu-category.component.scss']
})
export class MenuCategoryComponent {
    @Input()
    category: ProductCategory = undefined;

    @Output()
    select = new EventEmitter<UpdateOrderAction>()

    public isCollapsed = true;
    handleItemClick($event:UpdateOrderAction) {
      let el: UpdateOrderAction = { ...$event, category: this.category.name };
      this.select.emit(el)
    }
}
