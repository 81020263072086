import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http'; 
import { HttpModule } from '@angular/http';
//import { User } from '../models/user';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
//import { environment } from '../../../../environments/environment';

@Injectable()
export class MenuService {

  constructor(private http: HttpClient) { }

  // addUser(userModel: User): Observable<any> {
  //   return this.http.post(environment.apis.addUser, userModel);
  // }

  // getallUsers(): Observable<any> {
  //   return this.http.get(environment.apis.getallUsers);
  // }
  getMenu(): Observable<any> {
    return this.http.get(environment.apis.getMenu);
  }
  getMenuItems(): Observable<any> {
    return this.http.get(environment.apis.getMenuItems);
    }

  getVegMenu(): Observable<any> {
    return this.http.get(environment.apis.getVegMenu);
  }
}
