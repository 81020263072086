import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http'; 
import { HttpModule } from '@angular/http';
//import { User } from '../models/user';
import { Subject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Order } from '../models/order';
import { OrderItem } from '../models/orderitem';
//import { environment } from '../../../../environments/environment';

@Injectable()
export class OrderService {
  private _clear$ = new Subject<void>();
  constructor(private http: HttpClient) { }

  get clearOrder$() {
    return this._clear$.asObservable();
}

clearOrder() {
  
  this._clear$.next();
}

  createOrder(order: Order): Observable<any> {
    return this.http.post(environment.apis.createorder, order);
  }
  createOrderItems(orderItem: OrderItem): Observable<any> {
    return this.http.post(environment.apis.createorderItem, orderItem);
  }

  getOrderByPhnNo(tableno:String,phno:Number) : Observable<any> {
    return this.http.get(environment.apis.getOrder+tableno+"/"+phno);
  }

  getOrderByTable(tableno:String) : Observable<any>
  {
    return this.http.get(environment.apis.getOrderByTable+tableno);
  }

  getOrderDetails(): Observable<any> {
    return this.http.get(environment.apis.getOrderDetails);
  }

  updateorder(order : Order) : Observable<any> {
    return this.http.put(environment.apis.updateOrder+order[0]._id,order[0]);
  }

  updateOrderItem(orderItem: OrderItem) : Observable<any> {
    return this.http.put(environment.apis.updateOrderItem+orderItem._id, orderItem);
  }

  getOrderDetailsByStatus(status: string) : Observable<any> {
    return this.http.get(environment.apis.getOrderDetailsByStatus+status);
  }
}
