//import { Order } from '../../services/models/order';
import { Input, Component, OnInit, Output, EventEmitter } from '@angular/core';
import { OrderService } from '../../services/order-service/order-service';
import { Order} from '../../services/models/order';
import { OrderItem } from 'src/app/services/models/orderitem';
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { TableService } from '../../services/table-service/table-service';
import { Table } from 'src/app/services/models/table';

@Component({
    selector: 'app-order-status',
    templateUrl: './order-status.component.html',
    styleUrls: ['./order-status.component.scss']
})
export class OrderStatusComponent implements OnInit{
    @Input()
    order: Order = null;

    @Output()
    clearOrder = new EventEmitter<void>();

    constructor(private orderService:OrderService, private router:Router,
        private toastr: ToastrService, private tableService: TableService){}
    orderId : number = 0;
    orderItem:OrderItem = null;
    ordercounter =false;
    table:Table;
    preference: String;
   get Data()
    {
        return Object(sessionStorage);
    }

    Value(key)
    {
     var ret = sessionStorage[key];
     return ret;
    }


    ngOnInit(){
        this.load();
        this.orderService.getOrderByPhnNo(sessionStorage.getItem('tableno'),parseInt(sessionStorage.getItem('mobilenumber'))).subscribe(res=>{
            if(res.length>0)
                {
                    if(res[0]._id>0)
                    {
                        this.ordercounter = true;
                        console.log(this.ordercounter+"OrderCounter");
                    }
                }
        });
    }

    load()
    {
        var keys = Object.keys(sessionStorage);
        for(var i=0;i<keys.length;i++){
            var key = keys[i];
            console.log(key, sessionStorage[key]);
            //store here "both names" where you want them
            //you can also access each element with localStorage[key].name, localStorage[key].country, etc.
        }
    }
    clear() {
        var n = sessionStorage.length;
        while (n--) {
            var key = sessionStorage.key(n);
            if (key!='tableno'&& key!='mobilenumber'&&key!='username') {
                sessionStorage.removeItem(key);
            }
        }
    }

    createorder()
    {
        var keys = Object.keys(sessionStorage);
        this.tableService.getTableByNum(sessionStorage.getItem('tableno')).subscribe(res=>{
            if(res[0].currentUser == sessionStorage.getItem('username') +"/"+ sessionStorage.getItem('mobilenumber'))
            {
                if(keys.length>3)
                {   //check if there is any order header already created 
                    console.log(sessionStorage.getItem('mobilenumber'));
                    this.orderService.getOrderByPhnNo(sessionStorage.getItem('tableno'),parseInt(sessionStorage.getItem('mobilenumber'))).subscribe(res=>{
                        if(res.length>0)
                        {
                            if(res[0]._id>0)
                            {
                                this.orderId = res[0]._id;
                            }
                            if(this.orderId>0)
                            {
                                for(var i=0;i<keys.length;i++){
                                    var key = keys[i];
                                    if(key!='tableno' && key!='username' && key!='mobilenumber')
                                    {
                                        this.orderItem = new OrderItem(null,this.orderId,keys[i].split(',')[0],sessionStorage[keys[i]],this.preference,keys[i].split(',')[1],keys[i].split(',')[2],'')
                                        this.orderService.createOrderItems(this.orderItem).subscribe(res=>{
                                            console.log("Order Placed Successfully");
                                            this.toastr.success("Order Placed Successfully");
                                        });
                                    }   
                                }
                            }
                            this.clear();
        
                            this.router.navigate(['/orders']);    
                        }
                
                    if(this.orderId == 0)
                    //create order
                {
                    this.order = new Order(null,sessionStorage.getItem('tableno'),parseInt(sessionStorage.getItem('mobilenumber')),sessionStorage.getItem('username'),0,'New');
                    this.orderService.createOrder(this.order).subscribe(res=>{
                        this.orderId = res._id;
                        if(this.orderId>0)
                        {
                            for(var i=0;i<keys.length;i++){
                                var key = keys[i];
                                if(key!='tableno' && key!='username' && key!='mobilenumber')
                                {
                                    this.orderItem = new OrderItem(null,this.orderId,keys[i].split(',')[0],sessionStorage[keys[i]],this.preference,keys[i].split(',')[1],keys[i].split(',')[2],'')
                                    this.orderService.createOrderItems(this.orderItem).subscribe(res=>{
                                        console.log("Order Placed Successfully");
                                    });
                                }   
                            }
                            this.toastr.success("Order Placed Successfully");
                        }
                        this.clear();
                        // this.tableService.getTableByNum(sessionStorage.getItem('tableno')).subscribe(res=>
                        //     {
                        //         this.table = res[0];
                        //         this.table.currentUser = sessionStorage.getItem('username') +"/"+ sessionStorage.getItem('mobilenumber');
                        //         this.table.active = 'Yes';
                        //         if(res[0]!=null)
                        //         {
                        //                 this.tableService.updateTable(this.table).subscribe(res=>{
                        //                         console.log('table updated !!');
                        //                 });
                        //         }
                        //     });
                        this.router.navigate(['/orders']);
                    });
            
                }
        
                
                   
                });
                    
                }
                else{
                    this.toastr.warning("Please add items to Order!");
                }
            }
            else {
                this.toastr.warning("Please use the Earlier used username and phonenumber to place Order");
            }
        });


    }

    orders(){
        this.router.navigate(['/orders']);
    }

    ordercounters()
    {
        return this.ordercounter;
    }

}