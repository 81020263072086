import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalConstants } from '../../common/global-constants';

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    //{ path: '/dashboard', title: 'Dashboard',  icon: 'ni-tv-2 text-primary', class: '' },
    { path: '/tables', title: 'Tables',  icon:'ni-bullet-list-67 text-red', class: '' },
    { path: '/orders', title: 'Orders',  icon:'ni-tv-2 text-success', class:''},
    //{ path: '/thankyou', title:'Thankyou', icon:'', class:''}
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  public menuItems: any[];
  public isCollapsed = true;
  username : string;
  mobilenumber: string;
  constructor(private router: Router) { }

  ngOnInit() {
      this.username = sessionStorage.getItem('username');
      this.mobilenumber = sessionStorage.getItem('mobilenumber');
      var isadmin = sessionStorage.getItem('tableno').toString();
      if(isadmin == 'admin')
      {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        this.router.events.subscribe((event) => {
          this.isCollapsed = true;
       });
      }

  }
}
