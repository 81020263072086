//const baseUrl = 'https://ec2-18-223-112-209.us-east-2.compute.amazonaws.com:443/';
//const baseUrl = 'http://localhost:3000/';
const baseUrl = 'https://digitaldiner.onrender.com/';

export const environment = {
  production: true,
  apis:{
    getalltables: `${baseUrl}tables`,
    getMenu: `${baseUrl}MenuCategories`,
    getMenuItems: `${baseUrl}MenuItems`,
    createorder: `${baseUrl}order`,
    createorderItem: `${baseUrl}orderItem`,
    getOrder: `${baseUrl}order/getbytableandphno/`,
    getOrderDetails: `${baseUrl}orderDetails`,
    getOrderByTable: `${baseUrl}order/getorderbytableno/`,
    getVegMenu: `${baseUrl}VegMenuCategories`,
    updateOrder: `${baseUrl}order/`,
    updateOrderItem: `${baseUrl}orderItem/`,
    findTableByNum: `${baseUrl}tables/getTablebyNum/`,
    updatetable: `${baseUrl}tables/`,
    getUserbyUserName: `${baseUrl}user/getuserbyUserName`,
    createuser: `${baseUrl}user`,
    getOrderDetailsByStatus: `${baseUrl}getOrderDetailsByStatus/`,
    getOrderItems: `${baseUrl}orderItem`   
  }
};
