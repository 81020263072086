import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http'; 
import { HttpModule } from '@angular/http';
//import { User } from '../models/user';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Table } from '../models/table';

@Injectable()
export class TableService {

  constructor(private http: HttpClient) { }

  getalltables(): Observable<any> {
    return this.http.get(environment.apis.getalltables);
  }

  updateTable(table : Table): Observable<any> {
    return this.http.put(environment.apis.updatetable+table._id, table);
  }

  getTableByNum(tableno: String): Observable<any> {
    return this.http.get(environment.apis.findTableByNum+tableno);
  }
}
