import { Component, OnInit } from '@angular/core';
import { ProductCategory, Product, UpdateOrderAction } from '../../services/models/models';
import { Observable } from 'rxjs';
import { take, filter } from 'rxjs/operators'
//import { MenuService } from '../menu/menu.service';
import { ActivatedRoute } from '@angular/router';
//import { OrderService } from '../../services/order.service';
import { GlobalConstants } from '../../common/global-constants';
import { MenuService } from '../../services/menu-service/menu-service';
import { OrderService } from 'src/app/services/order-service/order-service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
    menu: Observable<ProductCategory[]>;
    vegonly = false;
    orderId: string;
    menuItems = [];
    username: string;
    public isCollapsed = true;
    order$: Observable<Product>;
    selectedItems:any = [];
    tableno: string;
    constructor(private activatedRoute: ActivatedRoute, private menuService: MenuService, private orderService: OrderService,
        private toastr: ToastrService) { }

    async ngOnInit() {
        this.username = sessionStorage.getItem('username');
        //this.mobilenumber = sessionStorage.getItem('mobilenumber');
        this.tableno = sessionStorage.getItem('tableno');
        if(this.vegonly)
        {
            this.menuService.getVegMenu().subscribe(res =>{
                this.menu = res[0].Result;
                console.log(this.menu);
             });
        }
        else {
            this.menuService.getMenu().subscribe(res =>{
                this.menu = res[0].Result;
                console.log(this.menu);
             });
             this.menuService.getMenuItems().subscribe(res =>{
                    this.menuItems = res;
                    console.log(this.menuItems);
             });
        }
    }

    onValChange(value){
        console.log(value);
        this.ngOnInit();
   }

    updateOrder(orderUpdate: UpdateOrderAction) {
        this.selectedItems = orderUpdate;
    }

    clearOrder()
    {
        this.orderService.clearOrder();
        this.toastr.success("Your Cart is Empty");
    }
}
